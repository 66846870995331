import React from "react";
import { connect } from "react-redux";
import { updateField } from "../../redux/update/updateAction";
import { Tabs, Tab } from "react-bootstrap";
import ListingDemandCard from "./ListingDemandCard";
import ListingSupplyCard from "./ListingSupplyCard";
import { useRouteMatch } from "react-router-dom";

const Lisiting = ({ updateField }) => {
  let match = useRouteMatch();
  return (
    <div className="listing-page">
      <Tabs
        defaultActiveKey="supply"
        className="supply-demand-tabs"
        onSelect={(crnt) => updateField("listingActiveTab", crnt)}
      >
        <Tab eventKey="supply" title={match.params.Type == 'Jobs'? 'Recruiters' : 'Buyers'}>
          <ListingSupplyCard />
        </Tab>
        <Tab eventKey="demand" title={match.params.Type == 'Jobs'? 'Candidates' : 'Sellers'}>
          <ListingDemandCard />
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    supplyList: state.update,
  };
};

const mapDispatchToProps = {
  updateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(Lisiting);
