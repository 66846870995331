import React from "react";
import cafe from "../../../images/cafe.jpg";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

const Card = ({ lists, categoryMain }) => {
  const useStyles = {
    dnone : {
      display: 'none'
    }
  }

  function viewallcategory(){
    let categoryviewall = document.getElementsByClassName("categoryviewall")
    for(var j = 0; j<categoryviewall.length;j++){
      document.getElementsByClassName("categoryviewall")[j].remove()
    } 
    let allelems = document.getElementsByClassName("hidencategories")
    for(var i = 0; i<allelems.length;i++){
      document.getElementsByClassName("hidencategories")[i].style.display = 'block'
    }       
  }

  return (
    <div className="category-card-main">
      <div className="row">
        {lists.map((list, index) => {
          return (
            <> {index < 9 ?<div className="col-md-4" key={index}>
              <Link to={`/listing/${categoryMain}/${list.id}`}>
                <div className="category-card">
                  <img src={`${list.file_path}`} width="100%" alt="" />
                  <div className="category-content">
                    <h6>{list.name}</h6>
                    <p>4 Locations</p>
                  </div>
                </div>
              </Link>
            </div>: <div className="col-md-4 hidencategories" style={useStyles.dnone} key={index}>
              <Link to={`/listing/${categoryMain}/${list.id}`}>
                <div className="category-card">
                  <img src={`${list.file_path}`} width="100%" alt="" />
                  <div className="category-content">
                    <h6>{list.name}</h6>
                    <p>4 Locations</p>
                  </div>
                </div>
              </Link>
            </div>}            
            </>
          );
        })}        
      </div>
      <div className="row">
        <Button 
          className="categoryviewall"
          variant="contained"
          color="primary"
          size="large"
          onClick={
            viewallcategory
          }>View All</Button>
      </div>      
    </div>
  );
};

export default Card;
