import React, { useRef, useState, useEffect } from "react";
import { Tabs, Tab, Form, Button, Spinner } from "react-bootstrap";
import { signUp, login } from "../../services/user";
import { setCookie, getCookie } from "../../utils/cookieUtil";
import { useHistory } from "react-router-dom";

const LoginSignup = () => {
  let history = useHistory();

  useEffect(() => {
    if(getCookie('is_logged')){
      window.location.href = '/';
    }
  });

  const [loginError, setLoginError] = useState(false);
  const [checkErrorLogin, setCheckErrorLogin] = useState(false);
  const [checkErrorRegister, setCheckErrorRegister] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  const [registerError, setRegisterError] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  const name = useRef(null);
  const signup_email = useRef(null);
  const signup_password = useRef(null);
  const password_conf = useRef(null);
  const register_terms = useRef(null);
  const login_email = useRef(null);
  const login_password = useRef(null);
  const login_terms = useRef(null);

  const handleSignUp = async (event) => {
    setCheckErrorRegister(false)
    setRegisterError(false)
    setRegisterLoading(true)
    event.preventDefault();
    if(!register_terms.current.checked){
      setRegisterLoading(false)  
      setCheckErrorRegister(true)
      return false;
    }
    const resp = await signUp({
      name: name.current.value,
      email: signup_email.current.value,
      password: signup_password.current.value,
      password_confirmation: password_conf.current.value
    });
    if (resp && resp.data && resp.status === 201) {
      setCookie("id", resp.data.id, 1);
      setCookie("is_logged", true, 1);
      window.location.href = '/';
    }else{
      setRegisterLoading(false)
      setRegisterError(true)      
    }
  };

  const handleLogin = async (event) => {
    setCheckErrorLogin(false)
    setLoginError(false)
    setLoginLoading(true)
    event.preventDefault();
    if(!login_terms.current.checked){
      setLoginLoading(false)  
      setCheckErrorLogin(true)
      return false;
    }
    let formData = new FormData();
    formData.append("email", login_email.current.value);
    formData.append("password", login_password.current.value);

    const resp = await login(formData);
    if (resp && resp.data && resp.status === 200) {  
      setLoginLoading(false)    
      setCookie("id", resp.data.id, 1);
      setCookie("is_logged", true, 1);
      window.location.href = '/';
    }else{
      setLoginLoading(false)  
      setLoginError(true)
    }
  };
  return (
    <div className="login-signup-main-div">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-4 col-sm-10">
            <Tabs defaultActiveKey="login" className="register-tab">
              <Tab
                eventKey="login"
                title="Login"
                className="register-tab-content"
              >
                <Form onSubmit={handleLogin}>
                  <Form.Group controlId="formBasicEmail">
                    <div style={{ color: "red" }}>{loginError? 'Login Failed, Please Try Again..':checkErrorLogin?'You must agree our Terms & Conditions.':''}</div>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      ref={login_email}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      ref={login_password}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <input type="checkbox" ref={login_terms} value={1}/>
                    <label style={{color:'#c4bfb9',marginLeft:'2%'}}> I Accept the <b style={{cursor:'pointer',color:'#fff',fontWeight:'500'}}>Terms of Use</b> & <b style={{cursor:'pointer',color:'#fff',fontWeight:'500'}}>Privacy Policy</b>.</label>
                    
                  </Form.Group>
                  <Button className="submit-btn" type="submit">
                    Submit {loginLoading? <Spinner animation="border" size="sm"/>: ''}
                  </Button>
                </Form>
              </Tab>
              <Tab
                eventKey="register"
                title="Register"
                className="register-tab-content"
              >
                <Form onSubmit={handleSignUp}>
                  <Form.Group>
                    <div style={{ color: "red" }}>{registerError? 'Registration Failed, Please Try Again..':checkErrorRegister?'You must agree our Terms & Conditions.':''}</div>
                    <Form.Control type="text" placeholder="Name" ref={name} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      ref={signup_email}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      ref={signup_password}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="password"
                      placeholder="Re-enter Password"
                      ref={password_conf}
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                    <input type="checkbox" ref={register_terms}/>
                    <label style={{color:'#c4bfb9',marginLeft:'2%'}}> I Accept the <b style={{cursor:'pointer',color:'#fff',fontWeight:'500'}}>Terms of Use</b> & <b style={{cursor:'pointer',color:'#fff',fontWeight:'500'}}>Privacy Policy</b>.</label>
                    
                  </Form.Group>
                  <Button className="submit-btn" type="submit">
                    Submit {registerLoading? <Spinner animation="border" size="sm"/>: ''}
                  </Button>
                </Form>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
