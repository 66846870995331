import React, { useEffect, useState, useCallback } from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import * as categorie from "../services/categories";
import { NavLink, Link } from "react-router-dom";
import * as bs from "../services/buyersSellers";
import Container from "@material-ui/core/Container";
import CurSepper from "./common/Stepper";
import SimpleCard from "./common/Cards";
import Grid from "@material-ui/core/Grid";
import CustomDialog from "./common/Dialog";
import { ButtonBase, Paper } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ImageButton from "./common/ImageButton";
import TextField from "@material-ui/core/TextField";
import PostAdForm from "./PostAdForm";
import { getCookie } from "../utils/cookieUtil";
import { useHistory } from "react-router-dom";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180
  },
  wrapper: {
    width: 100 + theme.spacing(2)
  },
  paper: {
    zIndex: 1,
    position: "relative",
    margin: theme.spacing(1),
    height: "200px"
  }
}));


const AddPost = () => {
  const classes = useStyles();

  const [images_buyer, setImagesBuyer] = useState("IAM A BUYER");
  const [images_seller, setImagesSeller] = useState("IAM A SELLER");

  const images = [
    {
      url:
        "https://cdn.iconscout.com/icon/premium/png-256-thumb/buyer-12-180587.png",
      title: images_buyer,
      width: "100%",
   
      onClick: () => {
        handleBuyerSeller("buy", "buyer");
      }
    },
    {
      url: "https://static.thenounproject.com/png/2435334-200.png",
      title: images_seller,
      width: "100%",
  
      onClick: () => {
        handleBuyerSeller("sell", "seller");
      }
    }
  ];

  const [getSteps, setGetSteps] = useState([
    "Select Ad Type",
    "Select Buyer/Seller",
    "Select sub Category",
    "Create Ad"
  ]);

  const [adType, setAdType] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  let history = useHistory();
  const [formData, setFormData] = React.useState({
    data: {
      category_id: "",
      sub_category_id: "",
      title: "",
      description: "",
      supply_type: "",
      area: "",
      price: "",
      org_name: "",
      incharge: "",
      phone: "",
      alt_phone: "",
      contact_mode: "",
      email: "",
      city: "",
      state: "",
      address: "",
      zip_code: "",
      interview_date: "",
      interview_time: "",
      interview_location: "",
      job_location: "",
      position: "",
      education: "",
      experience: "",
      level: ""
    },
    categories: null,
    sub_categories: null,
    supply_type: [
      { name: "buy" },
      { name: "rent" },
      { name: "lease" },
      { name: "invest" }
    ],
    errors: {},
    buyerSeller: "buyer"
  });

  const userid = getCookie("id");
  if (userid === "" || userid === undefined || userid === null) {
    history.push( "/register");
  }

  const checkIsJobDetailsNeeded = useCallback(() => {
    if (
      formData.data.category_id &&
      formData.categories &&
      formData.data.category_id === 3
    ) {
      return true;
    } else {
      return false;
    }
  }, [formData]);
  const handleCategory = (curAdType) => { 
    if(curAdType == 3){
      setGetSteps(
        [
          "Select Ad Type",
          "Select Recruiter/Candidate",
          "Select sub Category",
          "Create Ad"
        ]
      );
      setImagesBuyer("IAM A RECRUITER")
      setImagesSeller("IAM A CANDIDATE")
    }
    setFormData({
      ...formData,
      data: { ...formData.data, category_id: curAdType }
    });
    setActiveStep(1);
  };
  const handleSubCategory = (sub_category_id) => {
    setFormData({ ...formData, data: { ...formData.data, sub_category_id } });
    setActiveStep(3);
  };
  useEffect(() => {
    const fetchCategories = async () => {
      const { data: categories } = await categorie.getcategories();
      const { data: sub_categories } = await categorie.getsubcategories();
      
      const temp_formData = formData;
      temp_formData.categories = categories;
      temp_formData.sub_categories = sub_categories;
      
      setFormData({ ...formData, categories, sub_categories });
    };
    fetchCategories();
  }, []);

  function sellersUrl() {
    const userid = getCookie("id");
    if (userid === "" || userid === undefined || userid === null) {
      throw "no user found";
    }
    return process.env.REACT_APP_API_URL + "/sellers/" + userid + "/supplies";
  }
  
  function buyersUrl() {
    const userid = getCookie("id");
    if (userid === "" || userid === undefined || userid === null) {
      throw "no user found";
    }
    return process.env.REACT_APP_API_URL + "/buyers/" + userid + "/requirements";
  }
  

  const doSubmit = async (curFormData) => {    
    const { data } = curFormData
    console.log(data)
    var form_Data = new FormData();
    form_Data.append('address', data.address);
    form_Data.append('alt_phone', data.alt_phone);
    form_Data.append('area', data.area);
    form_Data.append('category_id', data.category_id);
    form_Data.append('city', data.city);
    form_Data.append('contact_mode', data.contact_mode);
    form_Data.append('description', data.description);
    form_Data.append('education', data.education);
    form_Data.append('email', data.email);
    form_Data.append('experience', data.experience);
    form_Data.append('incharge', data.incharge);
    form_Data.append('interview_date', data.interview_date);
    form_Data.append('interview_location', data.interview_location);
    form_Data.append('interview_time', data.interview_time);
    form_Data.append('job_location', data.job_location);
    form_Data.append('level', data.level);
    form_Data.append('org_name', data.org_name);
    form_Data.append('phone', data.phone);
    form_Data.append('position', data.position);
    form_Data.append('price', data.price);
    form_Data.append('state', data.state);
    form_Data.append('sub_category_id', data.sub_category_id);
    form_Data.append('supply_type', data.supply_type);
    form_Data.append('title', data.title);
    form_Data.append('zip_code', data.zip_code);
    if(data.file){      
      form_Data.append('file', data.file);
    }else{
      console.log('no file exists')
    }

    if (formData.buyerSeller === "seller") {
      console.log('if');console.log(form_Data);console.log(form_Data.get('file'));
      axios
      .post(sellersUrl(), form_Data, {
        headers: { 'Content-Type': 'multipart/form-data' } 
      })
      .then(function(success) { 
        let listtype = ''
        if(success.data.category_id == 1){
          listtype = "Spaces"
        }else if(success.data.category_id == 2){
          listtype = "UsedEquipments"
        }else if(success.data.category_id == 3){
          listtype = "Jobs"
        }else{
          listtype = "Deals"
        }
        history.push("/listing/"+listtype+"/"+success.data.category_id+"");    
      });

      // const success = await bs.postSellers(form_Data);
      // if (success) {
      //   let listtype = ''
      //   if(success.data.category_id == 1){
      //     listtype = "Spaces"
      //   }else if(success.data.category_id == 2){
      //     listtype = "UsedEquipments"
      //   }else if(success.data.category_id == 3){
      //     listtype = "Jobs"
      //   }else{
      //     listtype = "Deals"
      //   }
      //   history.push("/listing/"+listtype+"/"+success.data.category_id+"");
      // }
    } else {
      console.log('else');console.log(form_Data);console.log(form_Data.get('file'));
      axios
      .post(buyersUrl(), form_Data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(function(success) { 
        let listtype = ''
        if(success.data.category_id == 1){
          listtype = "Spaces"
        }else if(success.data.category_id == 2){
          listtype = "UsedEquipments"
        }else if(success.data.category_id == 3){
          listtype = "Jobs"
        }else{
          listtype = "Deals"
        }
        history.push("/listing/"+listtype+"/"+success.data.category_id+"");
      });
      // const success = await bs.postbuyers(form_Data);
      // if (success) {
      //   let listtype = ''
      //   if(success.data.category_id == 1){
      //     listtype = "Spaces"
      //   }else if(success.data.category_id == 2){
      //     listtype = "UsedEquipments"
      //   }else if(success.data.category_id == 3){
      //     listtype = "Jobs"
      //   }else{
      //     listtype = "Deals"
      //   }
      //   history.push("/listing/"+listtype+"/"+success.data.category_id+"");
      // }
    }
  };

  const handleBuyerSeller = (supply_type, user_type) => {
    setFormData({
      ...formData,
      buyerSeller: user_type,
      data: { ...formData.data, supply_type: supply_type }
    });
    setActiveStep(2);
  };

  return (
    <Container>
      <CurSepper steps={getSteps} curStep={activeStep} />

      {adType && <CustomDialog open={true} />}
      {/* to show loading page since the inital data are not yet loaded */}
      {activeStep === 0 && formData.categories === [] && (
        <Typography> Loading..</Typography>
      )}
      {/* first step select ad type */}
      {activeStep === 0 && formData.categories != null && (
        <Grid container spacing={2} justify="center">
          {formData.categories.map((category) => (
            <Grid item xs={6}>
              {" "}
              <Paper elevation={4} className={classes.paper}>
                <ImageButton
                  image={{
                    title: category.name,
                    width: "100%",
                    onClick: () => {
                      handleCategory(category.id);
                    },
                    url: category.file_path
                  }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {/*second step - buyer seller selecting step */}
      {activeStep === 1 && (
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
          <Grid container  justify="center" spacing={0}>
            {images.map((image) => (
               <Grid item  elevation={4} className={classes.paper} spacing={0} xs={6}>
              <ImageButton image={image} />
              </Grid>
            ))}
            </Grid>
         
        </Slide>
      )}
      {/* third step select ad sub type */}
      {activeStep === 2 && (
        <Grid container spacing={2} justify="center">          
          {formData.sub_categories.map(
            (sub_category) =>
              sub_category.category_id === formData.data.category_id && (
                sub_category.category_id == 3 ? formData.buyerSeller == "buyer"? sub_category.buyerSeller == "buyer"?
                <Grid item xs={6}>
                  {" "}
                  <Paper elevation={4} className={classes.paper}>
                    <ImageButton
                      image={{
                        title: sub_category.name,
                        width: "100%",
                        onClick: () => {
                          handleSubCategory(sub_category.id);
                        },
                        url: sub_category.file_path
                      }}
                    />
                  </Paper>
                </Grid>:''
                :formData.buyerSeller =="seller" ? sub_category.buyerSeller == "seller"?
                <Grid item xs={6}>
                  {" "}
                  <Paper elevation={4} className={classes.paper}>
                    <ImageButton
                      image={{
                        title: sub_category.name,
                        width: "100%",
                        onClick: () => {
                          handleSubCategory(sub_category.id);
                        },
                        url: sub_category.file_path
                      }}
                    />
                  </Paper>
                </Grid>:''
                :'':<Grid item xs={6}>
                  {" "}
                  <Paper elevation={4} className={classes.paper}>
                    <ImageButton
                      image={{
                        title: sub_category.name,
                        width: "100%",
                        onClick: () => {
                          handleSubCategory(sub_category.id);
                        },
                        url: sub_category.file_path
                      }}
                    />
                  </Paper>
                </Grid>
              )
          )}
        </Grid>
      )}

      {/* fourth type select contact details */}
      {activeStep === 3 && (
        <Slide direction="right" in={true} mountOnEnter unmountOnExit>
          <div>
            <PostAdForm
              isJobDetailsNeeded={checkIsJobDetailsNeeded()}
              doSubmit={doSubmit}
              formData={formData}
            />
          </div>
        </Slide>
      )}
    </Container>
  );
};

export default AddPost;
