import React, { useRef, useState } from "react";
import { Navbar, Nav, Form, FormControl, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from ".././images/logo/ohtel-logo.png";
import search from ".././images/icons/search.png";
import user from ".././images/icons/user.png";
import userIcon from ".././images/icons/ohtel_user_icon.png";
import plus from ".././images/icons/plus.png";
import { setCookie, getCookie, deleteCookie } from "../utils/cookieUtil";
import { useHistory, useRouteMatch } from "react-router-dom";

const NavBar = () => {
  let match = useRouteMatch();
  let history = useHistory();
  const search_key_word = useRef(null);

  function searchFunction(){
    history.push("/search/"+search_key_word.current.value);
  }

  function logout(){
    deleteCookie("id")
    deleteCookie("is_logged")
    window.location.href = '/';
  }
  const [is_logged_in, setIsLoggedIn] = useState(getCookie('is_logged'));
  return (
    <>
      <Navbar bg='dark' expand='lg' className='nav-main'>
        <Navbar.Brand className='brand-logo'>
          <Link to='/'>
            <img src={logo} alt='logo' />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav' className='navbar-collapse'>
          <div className='search-bar'>
            <img src={search} alt='search-icon' className='search-icon' />
            <Form inline className='search-bar-input' onSubmit={searchFunction}>
              <FormControl
                type='text'
                placeholder='Search For Spaces, Rent, etc..'
                className='mr-sm-2'
                ref={search_key_word}
              />
            </Form>
          </div>
          <Nav className='nav-bar-links'>
            {!is_logged_in?<Nav.Link className='nav-bar-link'>
              <Link to='/register'>
                <img src={user} alt='user' /> Login/Register 
              </Link>
            </Nav.Link>:''}            
            <Nav.Link className='nav-bar-link'>
              <Link to='/addpost'>
                <img src={plus} alt='' /> Post an AD
              </Link>
            </Nav.Link>
            {is_logged_in?<Dropdown style={{
              display: 'flex'
            }}>
              <img src={userIcon} alt='user' style={{width: "35px", height: "35px"}}/>
              <Dropdown.Toggle style={{
                backgroundColor: '#343a40',
                border: '1px solid #343a40'
              }}>
                {/*  */}
              </Dropdown.Toggle>

              <Dropdown.Menu >
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>:''}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
