import React, { useEffect } from 'react'
import CategoryTabs from './sections/CategoryTabs'
import ChatSec from './sections/ChatSec'
import SlideSection from './sections/SlideSection'
import TopDeals from './sections/TopDeals'
import WhyUs from './sections/WhyUs'


const Home = () => {
    
    useEffect(() => {
        console.log('sas')
        navigator.geolocation.getCurrentPosition(function(position) {
            console.log(position)
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
        });
    })
    return (
        <div>
            <SlideSection />
            <CategoryTabs />
            <WhyUs />
            <TopDeals />
            <ChatSec />
        </div>
    )
} 

export default Home
