import React from "react";
import { connect } from "react-redux";
import { updateField } from "../../redux/update/updateAction";
import { Tabs, Tab } from "react-bootstrap";
import SearchListBuyer from "./SearchListBuyer";
import SearchListSeller from "./SearchListSeller";
import { useRouteMatch } from "react-router-dom";

const SearchList = ({ updateField }) => {
  let match = useRouteMatch();
  return (
    <div className="listing-page">
      <Tabs
        defaultActiveKey="supply"
        className="supply-demand-tabs"
        onSelect={(crnt) => updateField("listingActiveTab", crnt)}
      >
        <Tab eventKey="supply" title={'Buyers'}>
          <SearchListBuyer />
        </Tab>
        <Tab eventKey="demand" title={'Sellers'}>
          <SearchListSeller />
        </Tab>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    supplyList: state.update,
  };
};

const mapDispatchToProps = {
  updateField,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
