import React from "react";
import { TextField, Select, MenuItem, InputLabel, Checkbox, FormControlLabel } from '@material-ui/core';
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Button, Grid } from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { green, purple } from "@material-ui/core/colors";
import GoogleMapReact from 'google-map-react';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch"
    }
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: green
  }
});

export default function PostAdForm(props) {
  var defaultMapProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
  const AnyMapReactComponent = ({ text }) => <div>{text}</div>;

  const classes = useStyles();
  const [formData, setFormData] = React.useState(props.formData);
  const [selectType, setType] = React.useState('');
  const [selectEducation, setEducation] = React.useState('');
  const [selectExperience, setExperience] = React.useState('');
  const [formLoading, setFormLoading] = React.useState(false);
  const [modeofcontact, setModeofcontact] = React.useState({
    modemobile: false,
    modeemail: false,
    modewalkin: false,
    termsofuse:true
  });

  const chooseModeofcontact = (event) => {
    setModeofcontact({ ...modeofcontact, [event.target.name]: event.target.checked });
  };

  const updateData = (event) => {
    const data = { ...formData.data };
    data[event.target.name] = event.target.value;
    setFormData({ ...formData, data });
  };

  const onChangeFile = (file) => {
    let files = file.target.files;
    let data = { ...formData.data };
    // let uploadFiles = []
    data['file'] = [];
    for (let i = 0; i < files.length; i++) {  
      // uploadFiles.push({
      //   fileInput: files[i],
      //   fileName: files[i].name
      // });
      data['file'].push({
        fileInput: files[i],
        fileName: files[i].name
      })
    }  
    setFormData({ ...formData, data });
  };

  const changeType = (event) => {
    setType(event.target.value);
  };

  const changeEducation = (event) => {
    setEducation(event.target.value);
  };

  const changeExperience = (event) => {
    setExperience(event.target.value);
  };

  return (
    <form
      enctype="multipart/form-data"
      className={classes.root}
      validate
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        setFormLoading(true)
        props.doSubmit(formData);
      }}
    >
      <Divider variant="middle" style={{ margin: "10px" }} />
      <div>
        <TextField
          id="title"
          label="Title"
          variant="outlined"
          size="Normal"
          name="title"
          style={{ width: "100%" }}
          onChange={updateData}
        />
      </div>
      <div>
        <TextField
          id="description"
          name="description"
          label="Description"
          multiline
          rows={4}
          variant="outlined"
          size="normal"
          style={{ width: "100%" }}
          onChange={updateData}
        />
      </div>

      <Divider variant="middle" style={{ margin: "10px" }} />
        <InputLabel id="demo-customized-select-label" style={{
              width:'99%',
              margin:'10px'
            }}>Select Images</InputLabel>
      <div style={{padding: "18.5px 14px"}}>
      <input
        name="pictures"
        type="file"
        id="pictures"
        accept="image/*"
        multiple
        onChange={onChangeFile}
      />
      </div>
      <Divider variant="middle" style={{ margin: "10px" }} />

      {formData.data.category_id == 3 && formData.buyerSeller == "buyer"?
      <>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={6}>
            <TextField
              id="org_name"
              label="Name Of The Organization"
              name="org_name"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="incharge"
              label="Contact Person"
              name="incharge"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="interviewdate"
              label="Interview Date"
              name="interviewdate"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="interviewtime"
              label="Interview Time"
              name="interviewtime"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="interviewaddress"
              label="Interview Address"
              name="interviewaddress"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="demo-customized-select-label" style={{
              margin:'10px'
            }}>Mode of Contact</InputLabel>
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.modemobile} onChange={chooseModeofcontact} name="modemobile" />}
              label="Mobile"
            />
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.modeemail} onChange={chooseModeofcontact} name="modeemail" />}
              label="Email"
            />
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.modewalkin} onChange={chooseModeofcontact} name="modewalkin" />}
              label="Walk-in"
            />
          </Grid>
        </Grid>
        <Divider variant="middle" style={{ margin: "10px" }} />
      <div>
        <Grid item xs={12}>
          <h5 style={{
            textAlign:'center',
            margin:'2%'
          }}>User Information</h5>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid xs={6} item>
            <TextField
              id="username"
              name="username"
              label="Name"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="userphonenumber"
              name="userphonenumber"
              label="Phone Number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="city"
              name="city"
              label="City"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="state"
              name="state"
              label="State"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>          
          <Grid xs={6} item>
            <TextField
              id="zip_code"
              name="zip_code"
              label="Zip Code"
              type="number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="address"
              name="address"
              label="Address"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
        </Grid>
        <div style={{ height: '40vh', width: '100%', margin: '10px' }}>
              <GoogleMapReact
                bootstrapURLKeys={{key:'AIzaSyAX5mR4dkJxwJnCBstgv3VsmAvYTzZdWxw'}}
                defaultCenter={{
                  lat: 8.9602,
                  lng: 76.6788
                }}
                defaultZoom={11} 
              >
                <AnyMapReactComponent
                  lat={8.9602}
                  lng={76.6788}
                  text="My Marker"
                />
              </GoogleMapReact>
              </div>
        <Grid item xs={12}>
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.termsofuse} onChange={chooseModeofcontact} name="termsofuse" />}
              label="I Accept the Terms of Use & Privacy Policy."
            />
          </Grid>
           
      </div>
      </>
      :formData.data.category_id == 3 && formData.buyerSeller == "seller"?
      <>
       <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <InputLabel id="demo-customized-select-label" style={{
              width:'99%',
              margin:'10px'
            }}>Select Resume</InputLabel>
            <input
              name="resume"
              type="file"
              id="resume"
              style={{
                margin:'10px'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="phone"
              label="Contact Number"
              name="phone"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="alt_phone"
              label="Alternate Contact Number"
              name="alt_phone"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel style={{
              margin:'10px'
            }}>Education</InputLabel>
            <Select labelId="label" id="education" value={selectEducation} style={{
              width:'99%',
              padding:'2px',
              margin:'10px'
            }}
            onChange={changeEducation}>
              <MenuItem value="Degree">Degree</MenuItem>
              <MenuItem value="SSLC">SSLC</MenuItem>
              <MenuItem value="Diploma">Diploma</MenuItem>
              <MenuItem value="Others">Others</MenuItem>
            </Select>            
          </Grid>
          <Grid item xs={6}>
            <InputLabel style={{
              margin:'10px'
            }}>Experience</InputLabel>
            <Select labelId="label" id="experience" value={selectExperience} style={{
              width:'99%',
              padding:'2px',
              margin:'10px'
            }}
            onChange={changeExperience}>
              <MenuItem value="0-1 Years">0-1 Years</MenuItem>
              <MenuItem value="1-2 Years">1-2 Years</MenuItem>
              <MenuItem value="2-3 Years">2-3 Years</MenuItem>
              <MenuItem value="3-4 Years">3-4 Years</MenuItem>
              <MenuItem value="4-5 Years">4-5 Years</MenuItem>
              <MenuItem value="5-6 Years">5-6 Years</MenuItem>
              <MenuItem value="6-7 Years">6-7 Years</MenuItem>
              <MenuItem value="7-8 Years">7-8 Years</MenuItem>
              <MenuItem value="8-9 Years">8-9 Years</MenuItem>
              <MenuItem value="9-10 Years">9-10 Years</MenuItem>
              <MenuItem value="10-11 Years">10-11 Years</MenuItem>
              <MenuItem value="11-12 Years">11-12 Years</MenuItem>
              <MenuItem value="12-13 Years">12-13 Years</MenuItem>
              <MenuItem value="13-14 Years">13-14 Years</MenuItem>
              <MenuItem value="14-15 Years">14-15 Years</MenuItem>
              <MenuItem value="15 Years+">15 Years+</MenuItem>
            </Select>            
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="preferredjoblocation"
              name="preferredjoblocation"
              label="Preferred Job Location (Specific City / Specific State)"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>          
        </Grid>
        <Divider variant="middle" style={{ margin: "10px" }} />
      <div>
        <Grid item xs={12}>
          <h5 style={{
            textAlign:'center',
            margin:'2%'
          }}>User Information</h5>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid xs={6} item>
            <TextField
              id="username"
              name="username"
              label="Name"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="userphonenumber"
              name="userphonenumber"
              label="Phone Number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="city"
              name="city"
              label="City"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="state"
              name="state"
              label="State"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>          
          <Grid xs={6} item>
            <TextField
              id="zip_code"
              name="zip_code"
              label="Zip Code"
              type="number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="address"
              name="address"
              label="Address"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
        </Grid>
        <div style={{ height: '40vh', width: '100%', margin: '10px' }}>
              <GoogleMapReact
                bootstrapURLKeys={{key:'AIzaSyAX5mR4dkJxwJnCBstgv3VsmAvYTzZdWxw'}}
                defaultCenter={{
                  lat: 8.9602,
                  lng: 76.6788
                }}
                defaultZoom={11} 
              >
                <AnyMapReactComponent
                  lat={8.9602}
                  lng={76.6788}
                  text="My Marker"
                />
              </GoogleMapReact>
              </div>
        <Grid item xs={12}>
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.termsofuse} onChange={chooseModeofcontact} name="termsofuse" />}
              label="I Accept the Terms of Use & Privacy Policy."
            />
          </Grid>
           
      </div>
      </>
      : <><div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <InputLabel id="demo-customized-select-label"style={{
              width:'99%',
              margin:'10px'
            }}>Select Type</InputLabel>
            <Select labelId="label" id="type" value={selectType} style={{
              width:'99%',
              padding:'2px',
              margin:'10px'
            }}
            onChange={changeType}>
              <MenuItem value="Sell">Sell</MenuItem>
              <MenuItem value="Rent">Rent</MenuItem>
              <MenuItem value="Lease">Lease</MenuItem>
              <MenuItem value="Partner">Partner</MenuItem>
              <MenuItem value="Invest">Invest</MenuItem>
            </Select>            
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="org_name"
              label="Organization Name"
              name="org_name"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="incharge"
              label="Incharge"
              name="incharge"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          {formData.data.category_id != 3 ?<Grid item xs={6}>
            <TextField
              id="area"
              name="area"
              label="Area in square"
              type="number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>:''}
          <Grid item xs={formData.data.category_id != 3 ?6:12}>
            <TextField
              id="price"
              label={formData.data.category_id != 3 ?"Price in INR":"Salary"}
              name="price"
              type="number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
        </Grid>
      </div>
      <Divider variant="middle" style={{ margin: "10px" }} />
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          
          <Grid item xs={6}>
            <TextField
              id="phone"
              name="phone"
              style={{ width: "100%" }}
              name="phone"
              label="Phone"
              variant="outlined"
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="alt_phone"
              name="alt_phone"
              label="Alernate Phone"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="demo-customized-select-label" style={{
              margin:'10px'
            }}>Mode of Contact</InputLabel>
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.modemobile} onChange={chooseModeofcontact} name="modemobile" />}
              label="Mobile"
            />
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.modeemail} onChange={chooseModeofcontact} name="modeemail" />}
              label="Email"
            />
          </Grid>
        </Grid>
      </div>
      <Divider variant="middle" style={{ margin: "10px" }} />
      <div>
        <Grid item xs={12}>
          <h5 style={{
            textAlign:'center',
            margin:'2%'
          }}>User Information</h5>
        </Grid>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid xs={6} item>
            <TextField
              id="username"
              name="username"
              label="Name"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="userphonenumber"
              name="userphonenumber"
              label="Phone Number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="city"
              name="city"
              label="City"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="state"
              name="state"
              label="State"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>          
          <Grid xs={6} item>
            <TextField
              id="zip_code"
              name="zip_code"
              label="Zip Code"
              type="number"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              id="address"
              name="address"
              label="Address"
              variant="outlined"
              style={{ width: "100%" }}
              onChange={updateData}
            />
          </Grid>
        </Grid>
        <div style={{ height: '40vh', width: '100%', margin: '10px' }}>
              <GoogleMapReact
                bootstrapURLKeys={{key:'AIzaSyAX5mR4dkJxwJnCBstgv3VsmAvYTzZdWxw'}}
                defaultCenter={{
                  lat: 8.9602,
                  lng: 76.6788
                }}
                defaultZoom={11} 
              >
                <AnyMapReactComponent
                  lat={8.9602}
                  lng={76.6788}
                  text="My Marker"
                />
              </GoogleMapReact>
              </div>
        <Grid item xs={12}>
            <FormControlLabel style={{
              margin:'0px'
            }}
              control={<Checkbox checked={modeofcontact.termsofuse} onChange={chooseModeofcontact} name="termsofuse" />}
              label="I Accept the Terms of Use & Privacy Policy."
            />
          </Grid>
           
      </div>
      {props.isJobDetailsNeeded && (
        <>
          <Divider variant="middle" style={{ margin: "10px" }} />
          <div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={1}
            >
              <Grid xs={6} item>
                <TextField
                  id="job_location"
                  name="job_location"
                  label="Job Location"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={updateData}
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  id="position"
                  name="position"
                  label="Position"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={updateData}
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  id="education"
                  name="education"
                  label="Education"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={updateData}
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  id="experience"
                  name="experience"
                  label="Experience"
                  type="number"
                  variant="outlined"
                  style={{ width: "100%" }}
                  onChange={updateData}
                />
              </Grid>
            </Grid>
          </div>
        </>
      )}
      </>}
      
      <Divider variant="middle" style={{ margin: "10px" }} />
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <ThemeProvider theme={theme}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{color:'#fff'}}
          >
            Create Ad {formLoading? <Spinner animation="border" size="sm"/>: ''}
          </Button>
        </ThemeProvider>
      </Grid>
    </form>
  );
}
